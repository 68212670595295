import React from "react";
import {
  Paper,
  Container,
  Typography,
  Box,
  Link,
  Stack,
  Grid,
} from "@mui/material";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CopyrightIcon from "@mui/icons-material/Copyright";
import InstagramIcon from "@mui/icons-material/Instagram";

const phoneNumber = "435-669-2459";
const emailAddress = "antonio@redsandsfabrication.com";
const instagramLink = "https://www.instagram.com/redsandsfabrication/";
const copyrightYear = "2025";
const copyrightCompany = "Red Sands Fabrication LLC";

const Footer = () => {
  return (
    <>
      <Paper
        sx={{ backgroundColor: "#DDD0C8", boxShadow: "0 50vh 0 50vh #DDD0C8" }}
        square
      >
        <Container>
          <Box sx={{ padding: 1 }} display="flex" justifyContent="center">
            <Stack>
              <Link underline="none" href={`tel:${phoneNumber}`}>
                <Typography
                  color="#626567"
                  variant="body2"
                  paddingTop={1}
                  paddingBottom={0.75}
                  textAlign="center"
                >
                  <CallOutlinedIcon sx={{ verticalAlign: "middle" }} />
                  {` ${phoneNumber}`}
                </Typography>
              </Link>
              <Link underline="none" href={`mailto:${emailAddress}`}>
                <Typography
                  color="#626567"
                  variant="body2"
                  paddingTop={0.75}
                  paddingBottom={1}
                  textAlign="center"
                >
                  <EmailOutlinedIcon sx={{ verticalAlign: "middle" }} />
                  {` ${emailAddress}`}
                </Typography>
              </Link>
            </Stack>
          </Box>
          <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            paddingTop={1}
            paddingBottom={0.5}
          >
            <Link
              href={instagramLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Box sx={{ paddingRight: 0.5 }}>
                <InstagramIcon sx={{ color: "#626567" }} />
              </Box>
            </Link>
          </Grid>
          <Typography
            align="center"
            sx={{ paddingBottom: 1, verticalAlign: "bottom" }}
            variant="body2"
          >
            <CopyrightIcon
              fontSize="sm"
              sx={{ marginBottom: 0.4, verticalAlign: "middle" }}
            />
            {` ${copyrightYear} ${copyrightCompany}`}
          </Typography>
        </Container>
      </Paper>
    </>
  );
};

export default Footer;
